import React from 'react';
import '../../styles/Meteors.css';

export const Meteors = ({ number = 20, className }) => {
  const meteors = new Array(number).fill(null);

  return (
    <>
      {meteors.map((_, idx) => {
        const duration = Math.random() * (5 - 2) + 2;
        const delay = Math.random() * 3;
        const topPosition = `${Math.random() * 100}%`;
        const leftPosition = `${Math.random() * 100}%`;

        return (
          <span
            key={idx}
            className={`meteor ${className}`}
            style={{
              top: topPosition,
              left: leftPosition,
              animation: `meteor-effect ${duration}s linear ${delay}s infinite`,
            }}
          />
        );
      })}
    </>
  );
};

import React, { useRef } from 'react';
import { BackgroundGradient } from '../components/ui/background-gradient';
import '../styles/Contact.css'; // Ensure this path is correct
import emailjs from '@emailjs/browser'; // Ensure this import is based on your installation
import { motion } from 'framer-motion';

const sanitizeInput = (input) => input.replace(/(<([^>]+)>)/gi, '');

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    Object.values(form.current.elements).forEach(input => {
      if (input.name) {
        input.value = sanitizeInput(input.value);
      }
    });

    emailjs.sendForm('service_ou75dlf', 'template_35ny2og', form.current, 'jja2KZpN5W4Yt8gLN')
      .then(() => {
          console.log('SUCCESS!');
          alert('Email sent successfully!');
        }, 
        (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send email. Please try again later.');
        }
      );
  };

  return (
    <div className="contact-container">
      <motion.h1
        initial={{ opacity: 0.5, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8, ease: 'easeInOut' }}
        className="contact-title"
      >
        Contact Us
      </motion.h1>
      <BackgroundGradient className="background-gradient dark:background-gradient-dark">
        <form ref={form} onSubmit={sendEmail} className="p-3">
          <div className="mb-3">
            <label htmlFor="user_name" className="form-label">Name</label>
            <input type="text" className="form-control" name="user_name" required />
          </div>
          <div className="mb-3">
            <label htmlFor="user_email" className="form-label">Email</label>
            <input type="email" className="form-control" name="user_email" required />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea className="form-control" name="message" rows="3" required></textarea>
          </div>
          <input type="submit" value="Send" className="btn btn-primary" />
        </form>
      </BackgroundGradient>
    </div>
  );
}

export default Contact;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import video from '../assets/images/bg.mov';
import { NavLink } from 'react-router-dom';
import '../styles/HeroSection.css' // Make sure your styles are being imported

const HeroSection = () => {
  const [heading, setHeading] = useState('');
  const [showSubtitle, setShowSubtitle] = useState(false); // New state for controlling subtitle visibility
  const fullHeading = 'Weelcome to Tech For Sapien...';

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      // Update to check if we have reached the last character
      if (index < fullHeading.length - 1) {
        setHeading((prev) => prev + fullHeading.charAt(index));
      } else {
        // If it's the last character, append it and clear the interval
        clearInterval(timer);
        setShowSubtitle(true);
      }
      index++;
    }, 150);

    // Clean-up function to clear interval when the component unmounts
    return () => clearInterval(timer);
  }, [fullHeading]);

  // Custom style object to control vertical position
  const verticalStyle = {
    position: 'relative',
    top: '-35vh',
  };

  return (
    <div
      className="hero-section d-flex align-items-center justify-content-center"
    >
      <video
        autoPlay
        muted
        style={{
          position: 'absolute',
          width: '100%',
          left: '50%',
          top: '65%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)',
          zIndex: '0',
        }}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Container>
        <Row>
          <Col md={12} style={verticalStyle} className="text-center">
            <h1>{heading}</h1>
            {/* Apply a conditional class for the transition effect */}
            <p className={`hero-subtitle ${showSubtitle ? 'subtitle-transition' : ''}`}>Empowering Humanity, One Byte At A Time.</p>
            <NavLink to="/about-us" className="btn btn-outline-light">
              Learn More
            </NavLink>
          </Col>
        </Row>
        <Row className="mt-10"> {/* Adding margin top to separate this section from the above */}
          <Col md={12} className="text-center">
            <h2 className="newheading">Our Vision in Technology</h2> {/* Title you can modify as needed */}
            <p>Welcome to Tech For Sapien — the premier destination for cutting-edge app innovations. Our seasoned team is at the forefront of crafting unparalleled digital experiences. Driven by a passion for technological excellence, we are committed to solving complex real-world challenges, transforming visionary ideas into tangible solutions that enhance human well-being and simplify life. Join us in our mission to redefine the digital landscape and empower humanity through technology.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;

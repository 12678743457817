import React, { useEffect, useRef, useState } from "react";
import { createNoise3D } from "simplex-noise";
import {cn} from "../../utils/cn"; // Ensure correct import path

export const WavyBackground = ({
  children,
  className,
  containerClassName,
  colors,
  waveWidth,
  backgroundFill,
  blur = 10,
  speed = "fast",
  waveOpacity = 0.5,
  ...props
}) => {
  const noise = createNoise3D();
  const canvasRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  const handleResize = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getSpeed = () => {
    switch (speed) {
      case "slow":
        return 0.001;
      case "fast":
        return 0.002;
      default:
        return 0.001;
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationId;

    const render = () => {
      ctx.canvas.width = dimensions.width;
      ctx.canvas.height = dimensions.height;
      ctx.filter = `blur(${blur}px)`;
      ctx.fillStyle = backgroundFill || "black";
      ctx.globalAlpha = waveOpacity;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const waveColors = colors || [
        "#38bdf8", "#818cf8", "#c084fc", "#e879f9", "#22d3ee"
      ];

      let nt = 0;
      const drawWave = (n) => {
        nt += getSpeed();
        for (let i = 0; i < n; i++) {
          ctx.beginPath();
          ctx.lineWidth = waveWidth || 50;
          ctx.strokeStyle = waveColors[i % waveColors.length];
          for (let x = 0; x < ctx.canvas.width; x += 5) {
            const y = noise(x / 800, 0.3 * i, nt) * 100;
            ctx.lineTo(x, y + ctx.canvas.height * 0.5);
          }
          ctx.stroke();
          ctx.closePath();
        }
      };

      drawWave(5);
      animationId = requestAnimationFrame(render);
    };

    render();

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [dimensions, blur, backgroundFill, waveOpacity, colors, waveWidth, speed]);

  return (
    <div className={cn("h-screen flex flex-col items-center justify-center", containerClassName)}>
      <canvas className="absolute inset-0 z-0" ref={canvasRef} id="canvas" style={{ filter: `blur(${blur}px)` }}></canvas>
      <div className={cn("relative z-10", className)} {...props}>
        {children}
      </div>
    </div>
  );
};

import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logob.png';

const Header = () => {
  return (
    <Navbar expand="lg" variant="dark" style={{ background: 'linear-gradient(135deg, #00010b, #00010b)', boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)' }}>
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/" className="navbar-brand">
          <img
            src={logo}
            width="100"
            height="100"
            className="d-inline-block align-top"
            alt="Tech for Sapien Logo"
          />
          <h2 style={{ display: 'inline-block', margin: 25, verticalAlign: 'top' }}>Tech for Sapien</h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} to="/" exact activeClassName="active">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/products" activeClassName="active">Products</Nav.Link>
            <Nav.Link as={NavLink} to="/about-us" activeClassName="active">About Us</Nav.Link>
            <NavLink to="/contact" className="btn btn-outline-light">
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

import React from "react";
import { Meteors } from "./ui/meteors";
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import '../styles/Meteors.css';

function MeteorsDemo() {
    return (
      <div className="meteors-container">
        <Meteors number={100} className="meteor" />
        <div className="text-contents">
          <h1 className="font-bold text-xl text-white mb-4">
          Kitchen Canvas
          </h1>
          <p className="font-normal text-base text-white mb-4">
          Discover the art of cooking with Kitchen Canvas. Our product ensures a delightful culinary experience, combining elegance and functionality in your kitchen. Stay tuned for our innovative features that cater to both amateur cooks and professional chefs alike.
          </p>
          <NavLink to= "/JoinTheWaitlist"><Button variant="outline-light">Check Out Our Product!</Button></NavLink>
        </div>
      </div>
    );
  }
  

export default MeteorsDemo;
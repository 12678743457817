// JoinTheWaitlist.js
import React, { useState } from "react";
import { BackgroundBeams } from "./ui/background-beams";
import { Button } from 'react-bootstrap';
import '../styles/waitlist.css';

function JoinTheWaitlist() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('http://localhost:3001/waitlist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      if (response.ok) {
        alert(data.message);
      } else {
        throw new Error(data.error + ': ' + data.details);  // Provide more details in the alert
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while signing up for the waitlist: ' + error.message);
    }
  };  

  return (
    <div className="waitlist-container">
      <BackgroundBeams className="background-beams" />
      <div className="content-container">
        <h1 className="title">Join The Waitlist</h1>
        <p className="description">
          Discover the art of cooking with Kitchen Canvas. Our innovative product is designed to enhance your culinary experience with elegance and functionality. Join our waitlist today to be the first to experience the future of kitchen innovation with personalized cooking experience.
        </p>
        <form onSubmit={handleSubmit} className="form-container">
          <input
            type="email"
            placeholder="Enter your email"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button variant="outline-light" type="submit">Join Waitlist</Button>
        </form>
      </div>
    </div>
  );
}

export default JoinTheWaitlist;

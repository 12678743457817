import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/products.css';
import kitchenCanvasLogo from '../assets/images/kclogo.jpeg'; // Update with the path to your image
import VortexDemo from './VortexDemo';
import MeteorsDemo from './Meteors';

const Products = () => {
  return (
    <>
     <div style={{ backgroundColor: '#00010b', color: '#fff', padding: '50px 0' }}>
      {/* Vortex Section */}
      <VortexDemo />

      {/* Products Section */}
      <div className="products-section" style={{ backgroundColor: '#00010b', color: '#fff', padding: '50px 0' }}>
        <h1 className="Coming-soon-title" style={{ fontSize: '86px', textAlign: 'center'}}>Coming Soon:</h1>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <img src={kitchenCanvasLogo} alt="Kitchen Canvas" style={{ maxWidth: '100%', height: '100%', marginLeft:'325px', marginBottom: '20px' }} />
            </Col>
          </Row>
          <MeteorsDemo />
        </Container>
      </div>
      </div>
    </>
  );
};

export default Products;

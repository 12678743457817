import React from "react";
import { Vortex } from "./ui/vortex";
import '../styles/products.css';

function VortexDemo() {
  return (
    <div className="w-[calc(100%-4rem)] mx-auto rounded-md  h-[30rem] overflow-hidden">
      <Vortex
        backgroundColor="black"
        className="flex items-center flex-col justify-center w-full h-full"
      >
        <div className="text-content">
        <h2 className="title">
          Our Products!
        </h2>
        <p className="description">
        Discover our range of AI-powered applications at Tech for Sapien—where innovation meets inclusivity, designed to empower and connect communities globally.
        </p>
        </div>
      </Vortex>
    </div>
  );
}

export default VortexDemo;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Products from './components/Products';
import Footer from './components/footer.js';
import AboutUs from './components/aboutusHS';
import Contact from './components/Contact';
import JoinTheWaitlist  from './components/JoinTheWaitlist.js';
import MobileAlert from './components/alert/MobileAlert';

function App() {
  return (
    <Router>
      <div className="App">
      <MobileAlert />
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<HeroSection />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/JoinTheWaitlist" element={<JoinTheWaitlist />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

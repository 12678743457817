import React from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { WavyBackground } from "../components/ui/wavy-background";
import Karan from '../assets/images/karan.png';
import Gautam from '../assets/images/gautam.jpeg';
import '../styles/aboutus.css';

const AboutPage = () => {
  // Array containing details for each team member
  const teamMembers = [
    {
      name: "Gautam Agarwal",
      description: "CEO & CTO",
      image: Gautam,
      linkedInUrl: "https://www.linkedin.com/in/gautam-agarwal-633483193/"
    },
    {
      name: "Karan Shivhare",
      description: "CFO",
      image: Karan,
      linkedInUrl: "https://www.linkedin.com/in/karanshivhare/"
    }
  ];

  return (
    <div style={{ color: 'white', backgroundColor: 'black' }}>
      {/* Hero Section */}
      <WavyBackground className="container-style">
      <p className="heading-large">
        About Us
      </p>
      </WavyBackground>
      {/* Mission Statement */}
      <Container className="my-5">
        <Row>
          <Col md={12} className="text-center">
            <h2 className="headings"> Our Mission</h2>
            <p className="ms">At Tech for Sapien, our mission is clear: to leverage artificial intelligence to create technologies that empower individuals and communities around the globe. We are dedicated to developing applications that are not only innovative but also intuitive, accessible, and impactful. By focusing on user-centric design and sustainable technology, we strive to deliver solutions that improve quality of life, foster education, and promote inclusivity. Our goal is to be at the forefront of the technological revolution, driving progress that is ethical, responsible, and human-centric. Join us as we pave the way towards a smarter, more connected world.</p>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Row>
          <Col md={12} className="text-center">
            <h2 className="headings">Our Story</h2>
            <p className="ms">Welcome to Tech for Sapien! Founded by a group of passionate fresh graduates, our journey began in a small dorm room with big dreams. Armed with degrees in technology and an unquenchable thirst for innovation, we embarked on a mission to harness the potential of artificial intelligence. In the rapidly evolving digital landscape, we saw a powerful tool to create change — AI. By developing intuitive apps that integrate cutting-edge AI technologies, we aim to transform everyday experiences and solve real-world problems. At Tech for Sapien, we are more than just technologists; we are dreamers who believe in the power of technology to shape a better future.</p>
          </Col>
        </Row>
      </Container>

      {/* Team Section */}
      <Container className="my-6">
        <Row>
          <h2 className="headings">Meet Our Team</h2>
          {teamMembers.map((member, idx) => (
            <Col md={6} className="my-2" key={idx}> {/* Changed to md={6} for more space */}
              <Card className="team-card">
                <Card.Img variant="top" src={member.image} />
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Text>{member.description}</Card.Text>
                  <a href={member.linkedInUrl} className="linkedin-icon" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default AboutPage;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';
import '../styles/footer.css';

const Footer = () => {
    const footerStyle = {
        background: 'linear-gradient(135deg, #00010b, #00010b)',
        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
    };

    return (
        <footer style={footerStyle} className="text-light footer">
            <div className="container py-4">
                <div className="row align-items-center justify-content-between"> {/* Aligned items horizontally */}
                    <div className="col-md-6 mb-3 d-flex">
                        <h5>Tech For Sapien</h5>
                    </div>
                    <div className="col-md-6 mb-3 d-flex justify-content-end">
                        <div className="social-icons">
                            <a href="https://facebook.com" className="text-light mr-2"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href="https://www.instagram.com/techforsapien/" className="text-light mr-2"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="https://www.linkedin.com/company/tech-for-sapien/about/" className="text-light"><FontAwesomeIcon icon={faLinkedin} /></a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-3 text-center">
                        <h5>Links</h5>
                        <ul className="list-unstyled footer-links">
                            <li><NavLink to="/" className="text-light">Home</NavLink></li>
                            <li><NavLink to="/products" className="text-light">Products</NavLink></li>
                            <li><NavLink to="/about-us" className="text-light">About Us</NavLink></li>
                            <li><NavLink to="/contact" className="text-light">Contact Us</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

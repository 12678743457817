import React from 'react';
import './MobileAlert.css'; // We will create this CSS file next

const MobileAlert = () => {
  const handleClose = () => {
    document.getElementById('mobile-alert').style.display = 'none';
  };

  return (
    <div id="mobile-alert" className="mobile-alert">
      <div className="mobile-alert-content">
        <h2 className="mobileheading">Attention Required!</h2>
        <p>Please access this website using a desktop or laptop for the best experience. Full mobile compatibility will be available soon.</p>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default MobileAlert;

import React from "react";
import "../../styles/backgrounf-gradient.css";

export const BackgroundGradient = ({
  children,
  className,
  containerClassName,
  animate = true,
}) => {
  return (
    <div className={`gradient-container ${containerClassName}`}>
      <div
        className={`gradient-background ${animate ? "animate" : ""}`}
      ></div>
      <div className={`gradient-content ${className}`}>{children}</div>
    </div>
  );
};